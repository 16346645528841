import { useParams } from "react-router-dom";
import { Tabs, Tab, styled } from "@mui/material";
import { useEffect, useState } from "react";
import ResultsTab from "./components/ResultsTab/ResultsTab";
import QuestionTab from "./components/QuestionTab/QuestionTab";
import PreviewTab from "./components/PreviewTab/PreviewTab";

import { getSurvey, getSurveyAttempts } from "../../api/survey";

const MAX_REQUEST = 15;
const LIMIT = 1000;

const ResultsComponent = () => {
  const { surveyId } = useParams();
  const [tab, setTab] = useState("RESULTS");
  const [attempts, setAttempts] = useState({})
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState({})
  const [survey, setSurvey] = useState({})

  useEffect(() => {
    const getAttempts = async () => {
      try {
        setLoading(true)
        let attempts = [];
        let users = [];
        let reqs = 0
        let resp = await getSurveyAttempts(surveyId, "", LIMIT);
        attempts = [...resp.attempts]
        users = [...resp.users]
        while (reqs < MAX_REQUEST && resp.hasMore) {
          reqs++;
          resp = await getSurveyAttempts(surveyId, "", LIMIT, resp.lastKey);
          attempts = [...attempts, ...resp.attempts]
          users = [...users, ...resp.users]
        }
        setAttempts(attempts)
        setUsers(users)
        const sur = await getSurvey(surveyId);
        setSurvey(sur)

      } catch (e) {

      } finally {
        setLoading(false)
      }
    }
    getAttempts();
  }, [surveyId])

  const MyTabs = styled(Tabs)({
    borderBottom: "1px solid #e8e8e8",
    "& .MuiTabs-indicator": {
      backgroundColor: "2px solid #083971",
      height: 4,
    },
  });

  const MyTab = styled(Tab)({
    color: "black",
    fontWeight: "bold",
    "&.Mui-selected": {
      color: "black",
    },
  });

  return (
    <div style={{ marginTop: "20px" }}>
      <MyTabs value={tab} onChange={(e, v) => setTab(v)}>
        <MyTab className="tab" label="RESULTS" value="RESULTS" />
        <MyTab className="tab" label="QUESTIONS" value="QUESTIONS" />
        <MyTab className="tab" label="PREVIEW" value="PREVIEW" />
      </MyTabs>
      {loading ? <div> ....loading</div> :
        <>
          {tab === "RESULTS" && <ResultsTab attempts={attempts} users={users} surveyId={surveyId} />}
          {tab === "QUESTIONS" && <QuestionTab attempts={attempts} survey={survey} />}
          {tab === "PREVIEW" && <PreviewTab survey={survey} />}
        </>
      }
    </div>
  );
};

export default ResultsComponent;
