import "./ResponseTable.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import MUIDataTable from "mui-datatables";

const DownloadIcon = () => {
  return (
    <>
      <div style={{ height: 35 }} color="primary" className="download-icon">
        EXPORT RESPONSES
      </div>
    </>
  );
};

const ResponseTable = ({ data, modalData }) => {
  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              padding: 0,
            },
          },
        },
        MuiTableBody: {
          styleOverrides: {
            root: {
              border: "1px black",
              borderStyle: "solid none",
            },
          },
        },
        MUIDataTable: {
          styleOverrides: {
            root: {
              marginTop: 20,
              boxShadow: "none",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            contentWrapper: {
              justifyContent: "center",
            },
            toolButton: {
              marginLeft: 0,
              marginRight: 0,
              paddingLeft: 0,
              paddingRight: 0,
            },
            data: {
              fontWeight: "bolder",
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              padding: 5,
              borderStyle: "none",
              textAlign: "center",
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            root: {
              height: 22,
            },
            label: {
              paddingRight: 0,
              paddingLeft: 0,
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            className: "responseTable",
          },
          className: "responseTable",
        },
        MUIDataTablePagination: {
          styleOverrides: {
            navContainer: {
              justifyContent: "center",
            },
          },
        },
      },
    });

  const getTime = () => {
    let now = new Date();
    return `${now.getFullYear()}.${
      now.getMonth() + 1
    }.${now.getDate()}-${now.getHours()}.${now.getMinutes()}.${now.getSeconds()}`;
  };
  const responses = data
    .filter((i) => i.isCompleted)
    .map((i) => {
      return { response: i.answers[modalData.name] };
    })
    .filter((i) => i.response);

  const options = {
    rowsPerPageOptions: [],
    download: true,
    downloadOptions: {
      filename: `responses-${getTime()}.csv`,
    },
    pagination: false,
    sortOrder: {
      name: "response",
      direction: "asc",
    },
    print: false,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search Responses",
    sortFilterList: false,
    filter: false,
    viewColumns: false,
    responsive: "standard",
    selectableRows: "none",
    elevation: 2,
  };

  const columns = [
    {
      name: "response",
      label: "Response",
      options: {
        sort: true,
        filter: true,
        setCellHeaderProps: (val) => {
          return {
            className: "leftAlign",
            style: {
              paddingLeft: 20,
            },
          };
        },
        setCellProps: (val) => {
          return {
            className: "leftAlign leftPadding",
          };
        },
        customBodyRender: (val, tableMeta) => {
          if (val) return val;
        },
      },
    },
  ];

  const components = {
    icons: {
      DownloadIcon,
    },
  };

  return (
    <>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title="Responses"
          className="responseTable"
          data={responses}
          columns={columns}
          options={options}
          components={components}
        />
      </ThemeProvider>
    </>
  );
};

export default ResponseTable;
