import { useEffect, useState, useCallback } from "react";
import { putSurveyAttempt, getUploadUrl } from "../../../api/survey";
import axios from "axios";
import Storage from "../../../utils/Storage";
import LoadingPage from "../../../components/LoadingPage";

import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";
import "../index.css";

StylesManager.applyTheme("defaultV2");

const INTERVAL_LENGTH = 1000 * 60;

const SurveyComponent = ({
  userId,
  handleReset,
  id,
  instanceId,
  surveyJSON,
  registrationId,
  attemptNo,
  cachedData,
  isCompleted,
  setIsCompleted,
  viewOnly,
  showBackButton,
  backText,
  startNewAttempt,
}) => {
  const [model, setModel] = useState(undefined);
  const [error, setError] = useState("");

  const autoSaveData = useCallback(async () => {
    if (model.state === "starting") return; // don't autosave on start page
    try {
      let cache = Storage.getSurveyCache();
      if (cache !== null) {
        console.log("Updating remote cache...");
        await putSurveyAttempt(
          id,
          registrationId,
          attemptNo,
          instanceId,
          cache.data,
          {
            lastPageNo: cache.currPage,
          }
        );
      }
    } catch (e) {
      console.log(e);
    }
  }, [instanceId, model, registrationId, attemptNo, id]);

  const onUploadFile = async (survey, options) => {
    try {
      const urls = await Promise.all(
        options.files.map(async (file) => {
          const { uploadUrl, imageUrl } = await getUploadUrl(
            file.name,
            file.type,
            id
          );
          const config = {
            headers: {
              "Content-Type": file.type,
            },
          };
          await axios.put(uploadUrl, file, config);
          return { file, content: imageUrl };
        })
      );
      options.callback("success", urls);
    } catch (e) {
      options.callback("error", "Failed to upload File");
    }
  };

  useEffect(() => {
    const setsurveyModel = async () => {
      try {
        let model = new Model(surveyJSON);
        model.clear();
        model.sendResultOnPageNext = true;

        if (cachedData) {
          model.data = cachedData.data;
          console.log("Setting current page", cachedData.currPage);
          model.currentPageNo = cachedData.currPage;
        }

        if (isCompleted) {
          if (viewOnly) {
            model.mode = "display";
            model.questionsOnPageMode = "singlePage";
          } else {
            console.log("Completing survey");
            model.doComplete();
          }
        } else model.start();

        // model.mode = "display"
        setModel(model);
      } catch (err) {
        setError(err.message || "Unknown Error Occurred");
      }
    };
    setsurveyModel();
  }, [surveyJSON, cachedData]); // eslint-disable-line

  useEffect(() => {
    // useEffect for autoSave
    let autoSaveInterval;
    autoSaveInterval = setInterval(() => {
      if (!isCompleted) autoSaveData();
    }, INTERVAL_LENGTH);
    return () => {
      clearInterval(autoSaveInterval);
    };
  }, [isCompleted, autoSaveData]);

  const onComplete = async (survey, options) => {
    if (!isCompleted) {
      let rightAnswers = survey.getCorrectedAnswerCount();
      let questionCount = survey.getQuizQuestionCount();
      let grade = Math.round((rightAnswers / questionCount) * 100);
      Storage.setSurveyCache({
        data: survey.data,
        currPage: survey.currentPageNo,
        // survey.currentPageNo + 1 < survey.visiblePageCount
        // ? survey.currentPageNo + 1
        // : survey.currentPageNo,
        registrationId: registrationId,
        attemptNo: attemptNo,
        completed: true,
        grade,
      });
      if (isNaN(grade)) grade = undefined; // if grade is NaN
      setIsCompleted(true);
      try {
        await putSurveyAttempt(
          id,
          registrationId,
          attemptNo,
          instanceId,
          survey.data,
          {
            grade: grade,
            isCompleted: true,
            endTime: Date.now(),
          }
        );
        if (survey.multipleSubmissions) {
          if (survey.submissionPastResults) {
            console.log("Reseting", survey.submissionPastResults);
            handleReset(true);
          } else {
            console.log("New", survey.submissionPastResults);
            if (!survey.showCompletedPage) {
              startNewAttempt();
            }
          }
        }
      } catch (e) {
        console.log(e);
        //TODO setup retry of attempt completion here
        setError("Issue Completing Survey");
      }
    }
  };

  const onPartialSend = (survey, options) => {
    Storage.setSurveyCache({
      data: survey.data,
      currPage:
        survey.currentPageNo + 1 < survey.visiblePageCount
          ? survey.currentPageNo + 1
          : survey.currentPageNo,
      registrationId,
      attemptNo,
      instanceId,
      surveyId: id,
      userId: userId,
      completed: false,
      grade: null,
      lastUpdated: new Date().toISOString(),
    });
    autoSaveData();
  };

  const onValueChanged = (survey, options) => {
    Storage.setSurveyCache({
      data: survey.data,
      currPage: survey.currentPageNo,
      registrationId: registrationId,
      attemptNo,
      instanceId: instanceId,
      surveyId: id,
      userId: userId,
      completed: false,
      grade: null,
      lastUpdated: new Date().toISOString(),
    });
  };

  if (error) return <LoadingPage type="error">{error}</LoadingPage>;

  if (model) {
    return (
      <>
        {showBackButton && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              margin: ".5rem",
            }}
          >
            <button onClick={() => handleReset(false)} className="button-link">
              {`< ${backText || "Back"}`}
            </button>
          </div>
        )}
        <Survey
          model={model}
          onValueChanged={onValueChanged}
          onPartialSend={onPartialSend}
          onComplete={onComplete}
          onUploadFiles={onUploadFile}
        />
      </>
    );
  }
  return (
    <LoadingPage type="loading" header="Loading">
      Launching...
    </LoadingPage>
  );
};

export default SurveyComponent;
