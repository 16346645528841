import { useEffect, useState } from "react";
import { getSurvey } from "../../../../../api/survey";
import * as Survey from "survey-core";
import * as ReactSurvey from "survey-react-ui";
import * as css from "survey-core/defaultV2.css";

import "./ViewAttempt.css";
import { Button } from "@mui/material";

Survey.StylesManager.applyTheme("defaultV2");

const ViewAttemptComponent = ({ attemptInfo, goBack }) => {
  const [model, setModel] = useState(undefined);
  const [error, setError] = useState("");

  const surveyId = attemptInfo.surveyIdUserIdRegistrationId.split("#")[0];

  useEffect(() => {
    const setSurveyModel = async () => {
      try {
        const surveyData = await getSurvey(surveyId);
        if (surveyData?.survey?.pages?.[0]) {
          let newModel = new ReactSurvey.Model(surveyData.survey);

          if (attemptInfo.answers) {
            // if there is data in db, fill data w/ db data
            newModel = showResults(newModel);
            newModel.data = attemptInfo.answers;
            newModel.start();
          }
          setModel(newModel);
        } else {
          setError("No Survey data found.");
        }
      } catch (err) {
        setError(err.message || "Unknown Error Occurred");
      }
    };

    setSurveyModel();
  }, [surveyId, attemptInfo.answers]);

  const showResults = (newModel) => {
    // newModel.clear(false);
    newModel.mode = "display";
    newModel.questionsOnPageMode = "singlePage";

    // getResults(newModel);
    return newModel;
  };

  if (error) return <div>{error}</div>;

  if (model) {
    return (
      <>
        <Button onClick={() => goBack()}>Go Back</Button>
        <ReactSurvey.Survey model={model} css={css} />
      </>
    );
  }
  return <div>loading</div>;
};

export default ViewAttemptComponent;
