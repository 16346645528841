const DEFAULT_CSS = {
  "--green": "#113483",
  "--primary": "#113483",
  "--primary-light": "#cbcbcb",
  "--secondary": "#676666",
  "--secondary-light": "#c9c8c8",
  "--secondary-back-light": "#eaeaea",
  "--background": "#ffffff",
  "--background-dim": "#f6f6f6",
  "--foreground": "#000000",
  "--foreground-light": "#000000",
  "--border-inside": "#262626",
  "--box-shadow-color": "#000029",
};

export default DEFAULT_CSS;

/*
{"--green": "#5f8311","--primary": "#ea0eed","--primary-light": "#8f6c79","--secondary": "#9e5d92","--secondary-light": "#be3434","--secondary-back-light": "#d12d2d"}
*/
