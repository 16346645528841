import { SvgIcon } from "@mui/material";
import { ReactComponent as tasks } from "./icons/tasks.svg";
import { ReactComponent as cmbLogo } from "./icons/combobulate-logo.svg";

export const SurveyBuilderIcon = (props) => {
  return (
    <SvgIcon {...props} component={tasks} viewBox="0 0 16 13.501"></SvgIcon>
  );
};
export const CombobulateLogo = (props) => {
  return (
    <SvgIcon
      {...props}
      component={cmbLogo}
      viewBox="0 0 1440.38 244.38"
    ></SvgIcon>
  );
};
