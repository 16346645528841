// import axios from "axios";
import axios from "./index";

export const getSurvey = async (id) => {
  const resp = await axios.get(`api/v1/survey/${id}`);
  return resp.data;
};

export const createSurvey = async (customization = {}, survey = null) => {
  const resp = await axios.post(`api/v1/survey/`, {
    customization,
    survey,
  });
  return resp.data;
};

export const listSurveys = async (accountId) => {
  const resp = await axios.get(`api/v1/survey/`, {
    params: { accountId },
  });
  return resp.data;
};

export const updateSurvey = async (id, survey) => {
  const resp = await axios.put(`api/v1/survey/${id}`, { survey });
  return resp.data;
};

export const deleteSurvey = async (id) => {
  const resp = await axios.delete(`api/v1/survey/${id}`);
  return resp.data;
};

export const getUploadUrl = async (fileName, contentType, surveyId) => {
  const resp = await axios.get(`api/v1/survey/image/upload`, {
    params: {
      fileName,
      contentType,
      surveyId,
    },
  });
  return resp.data;
};

export const getReportExport = async (surveyId) => {
  const resp = await axios.get(`api/v1/survey/report/export`, {
    params: {
      surveyId,
    },
  });
  return resp.data;
};

export const getSurveyAttempts = async (id, instanceId, limit = 1000, paginationKey) => {
  const resp = await axios.post(`api/v1/survey/${id}/attempts/many`, {
    instanceId,
    limit,
    paginationKey
  });
  return resp.data;
};

export const putSurveyAttempt = async (
  id,
  registrationId,
  attemptNo,
  instanceId,
  answers,
  otherData
) => {
  const resp = await axios.put(
    `api/v1/survey/${id}/attempts/${registrationId}${attemptNo !== null ? `%23${attemptNo}` : ""
    }`,
    {
      instanceId,
      answers,
      ...otherData,
    }
  );
  return resp.data;
};

export const createSurveyAttempt = async (
  id,
  instanceId,
  answers,
  otherData
) => {
  const resp = await axios.post(`api/v1/survey/${id}/attempts`, {
    answers,
    instanceId,
    ...otherData,
  });
  return resp.data;
};
