import Button from "@mui/material/Button";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import moment from "moment";
import Typography from "@mui/material/Typography";

const themeColor = localStorage.getItem("themeColor") || "#113483";

const theme = () =>
  createTheme({
    palette: {
      primary: {
        main: themeColor,
      },
    },
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            border: "none",
            marginLeft: "1rem",
            margin: ".5rem .5rem .5rem 1rem",
          },
        },
      },
      MUIDataTableBodyRow: {
        styleOverrides: {
          root: {
            "&:first-of-type": {
              borderTop: "1px solid black",
            },
            "&:nth-of-type(odd)": {
              backgroundColor: "#DDDDDD",
            },
            "&:nth-of-type(even)": {
              backgroundColor: "#EEEEEE",
            },
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            border: "0px",
          },
          contentWrapper: {
            marginLeft: "1rem",
          },
        },
      },
    },
  });

export default function AttemptsPage({
  limitReached,
  startNewAttempt,
  attempts,
  survey,
  viewAttempt,
  handleResume
}) {
  const columns = [
    {
      label: "Date/Time",
      name: "updatedAt",
      options: {
        sort: true,
        sortCompare: (order) => (obj1, obj2) => {
          return (new Date(obj1.data) - new Date(obj2.data)) *
            (order === "asc" ? 1 : -1)
        },
        customBodyRenderLite: (dataIndex) => {
          let val = moment(attempts[dataIndex].updatedAt).format(
            "dddd, MMMM D, YYYY h:mm:ssa"
          );
          return val;
        },
      },
    },
    {
      label: "View",
      name: "view",
      options: {
        customHeadRender: () => null,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const done = attempts[dataIndex].isCompleted
          return (
            <div>
              <button
                className="button-link"
                onClick={() => done ? viewAttempt(attempts[dataIndex]) : handleResume(attempts[dataIndex])}
              >
                {done ? "View" : "Resume"}
              </button>
            </div>
          );
        },
      },
    },
  ];
  const options = {
    elevation: 0,
    selectableRows: "none",
    responsive: "standard",
    search: false,
    print: false,
    filter: false,
    download: false,
    viewColumns: false,
    sortOrder: {
      name: 'updatedAt',
      direction: 'desc'
    },
    setTableProps: () => {
      return {
        padding: "none",
      };
    },
  };

  return (
    <ThemeProvider theme={theme()}>
      <div
        style={{
          display: "flex",
          justifyContent: survey?.submissionPastResults ? "space-between" : "center",
          alignItems: survey?.submissionPastResults ? "space-between" : "center",
          margin: "1rem",
          flexDirection: survey?.submissionPastResults ? "row" : "column"
        }}
      >
        <Typography variant="h6" gutterBottom={true} color="initial">
          {survey.title}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: '400px'
          }}
        >
          <Button
            disabled={limitReached}
            variant="contained"
            color="primary"
            onClick={startNewAttempt}
          >
            {survey?.submissionPrompt || "New Submission"}
          </Button>
          {survey.submissionLimit > 0 && (
            <span>
              limit: {attempts.length}/{survey.submissionLimit}
            </span>
          )}
        </div>
      </div>
      {survey.submissionPastResults ? (
        <MUIDataTable data={attempts} columns={columns} options={options} />
      ) : (
        ""
      )}
    </ThemeProvider>
  );
}
