import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getSurvey, getUploadUrl } from "../../api/survey";
import CustomPropertyGridWrapper from "./components/CustomPropertyGridWrapper";
import CustomToolboxWrapper from "./components/CustomToolboxWrapper";
import blockList from "../../utils/blockList";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import LoadingPage from "../../components/LoadingPage";

import axios from "axios";

import { ComponentCollection } from "survey-core";
import * as SurveyReact from "survey-react-ui";
// import * as SurveyCreatorCore from "survey-creator-core";
import * as SurveyCreator from "survey-creator-react";

import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import "./index.css";
import { Serializer } from "survey-core";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const options = {
  showTestSurveyTab: true,
  showJSONEditorTab: false,
  showLogicTab: false,
  showTranslationTab: false,
  showEmbeddedSurveyTab: false,
  haveCommercialLicense: true,
  // showErrorOnFailedSave: true,
  // isAutoSave: true,
  // showInvisibleElementsInTestSurveyTab: true,
  // showInvisibleElementsInPreviewTab: true,
  // showOptions: true,
  // useTabsInElementEditor: true,
  questionTypes: [
    "text",
    "checkbox",
    "dropdown",
    "radiogroup",
    "comment",
    "rating",
    "ranking",
    "imagepicker",
    "boolean",
    "file",
    "html",
  ],
};

ComponentCollection.Instance.add({
  name: "email",
  title: "Email",
  questionJSON: {
    type: "text",
    inputType: "email",
    name: "email",
    title: "Email",
  },
  onInit() {
    Serializer.addProperty("email", {
      category: "general",
      name: "sendEmail",
      displayName: "Send Email On Completion",
      type: "switch",
    });
    Serializer.addProperty("email", {
      category: "general",
      name: "subject",
      dependsOn: "sendEmail",
      displayName: "Email Subject",
      default: "{{title}}",
      type: "text",
      visibleIf: function (obj) {
        return !!obj ? obj.sendEmail : false;
      },
    });
  },
});

const creator = new SurveyCreator.SurveyCreator(options);
const curStrings = SurveyCreator.editorLocalization.getLocale("");

curStrings.pe.tabs.data = "Set Correct Answer";
curStrings.pe.maxTimeToFinish = "Maximum time to finish";
curStrings.pe.maxTimeToFinishPage = "Maximum time to finish a page";
curStrings.ed.surveyTypeName = "survey";
curStrings.ed.addNewQuestion = "Add Question";
curStrings.qt.boolean = "True / False";

// when false, removes device simulator in preview tab
creator.showSimulatorInPreviewTab = false;

Serializer.addProperty("question", {
  name: "id:text",
  category: "general",
  readOnly: true,
});

Serializer.addProperty("survey", {
  name: "multipleSubmissions:switch",
  displayName: "Allow Multiple Submissions",
  category: "general",
});
Serializer.addProperty("survey", {
  name: "submissionPrompt:text",
  displayName: "New Submission Prompt Text",
  dependsOn: "multipleSubmissions",
  default: "New Attempt",
  category: "general",
  visibleIf: function (obj) {
    return !!obj ? obj.multipleSubmissions : false;
  },
});
Serializer.addProperty("survey", {
  name: "submissionLimit:number",
  displayName: "Submission Limit",
  dependsOn: "multipleSubmissions",
  maxValue: 1000,
  minValue: 0,
  category: "general",
  visibleIf: function (obj) {
    return !!obj ? obj.multipleSubmissions : false;
  },
});
Serializer.addProperty("survey", {
  name: "submissionPastResults:switch",
  displayName: "Show Past Results",
  dependsOn: "multipleSubmissions",
  category: "general",
  visibleIf: function (obj) {
    return !!obj ? obj.multipleSubmissions : false;
  },
});

Serializer.findProperty("question", "visible").displayName = "Show question"; // change "is visible?" to "show question"
Serializer.findProperty("question", "isRequired").displayName = "Must answer"; // change "is required?" to "must answer"
Serializer.findProperty("file", "storeDataAsText").defaultValue = false;
Serializer.findProperty("file", "maxSize").defaultValue = 10000000;
Serializer.findProperty("file", "waitForUpload").defaultValue = true;

creator.onShowingProperty.add(function (sender, options) {
  if (Object.keys(blockList).includes(options.obj.getType())) {
    options.canShow = !blockList[options.obj.getType()].includes(
      options.property.name
    );
  }
});

// change question title 'boolean' to 'True / False'
creator.toolbox.getItemByName("boolean").title = "True / False";
creator.toolbox.getItemByName("boolean").tooltip = "True / False";

// removing delete option from start page and  Disclaimer html
creator.onElementAllowOperations.add((sender, options) => {
  if (options.obj.name === "Start_Page" || options.obj.name === "Disclaimer") {
    options.allowDelete = false;
    // options.allowEdit = false;
    options.allowChangeType = false;
  }
});

// Add text to collapse button
const collapseAction = creator.sidebar.toolbar.actions.filter(
  (action) => action.id === "svd-grid-hide"
)[0];
collapseAction.showTitle = true;
collapseAction.title = "Collapse";

//Store property grid survey to use it later
var propertyGridSurvey;
creator.onSurveyInstanceCreated.add((sender, options) => {
  if (options.reason !== "property-grid") return;
  propertyGridSurvey = options.survey;
});

//Property grid survey is setup on after new element is selected
creator.onSelectedElementChanged.add((sender, options) => {
  //Example If you wanted to show all properties and no dropdown
  if (!propertyGridSurvey) return;
  propertyGridSurvey.getAllPanels().forEach((p) => {
    p.visible = true;
    p.expand();
    // p.title = ""
  });

  // const page = propertyGridSurvey.pages[0];
  // const question = page.addNewQuestion("dropdown", "category", 0);
  // question.titleLocation = "hidden";
  // question.showOptionsCaption = false;

  // //Set choices based on panels
  // const categories = [];
  // propertyGridSurvey.getAllPanels().forEach((p) => {
  //   if (p.elements.some((e) => e.visible)) // only show panels that are allowed
  //     categories.push({ value: p.name, text: p.title });
  //   p.visible = false;
  //   p.expand();
  //   p.title = "";
  // });
  // question.choices = categories;

  // // Show / Hide panels
  // propertyGridSurvey.onValueChanged.add((sender, options) => {
  //   if (options.name !== "category") return;
  //   sender.getAllPanels().forEach((p) => {
  //     p.visible = question.value === p.name;
  //   });
  // });
  // question.value = categories[0].value;
  // propertyGridSurvey = undefined;
});

export default function Builder() {
  const { surveyId } = useParams();
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isModified, setIsModified] = useState(false);

  const setModify = (val) => {
    setIsModified(val);
    return;
  };

  SurveyReact.ReactElementFactory.Instance.registerElement(
    "svc-property-grid",
    (props) => {
      props.surveyId = surveyId;
      props.isModified = isModified;
      props.setModify = setModify;
      return React.createElement(CustomPropertyGridWrapper, props);
    }
  );

  SurveyReact.ReactElementFactory.Instance.registerElement(
    `svc-adaptive-toolbox`,
    (props) => {
      return React.createElement(CustomToolboxWrapper, props);
    }
  );

  useEffect(() => {
    function onLeave(e) {
      // Cancel the event as stated by the standard.
      e.preventDefault();
      if (isModified) e.returnValue = "Do you really want to leave this page?";
    }

    window.addEventListener("beforeunload", onLeave);

    return () => {
      window.removeEventListener("beforeunload", onLeave);
    };
  }, [isModified]);

  useEffect(() => {
    creator.onUploadFile.add(async (creator, options) => {
      const urls = await Promise.all(
        options.files.map(async (file) => {
          const { uploadUrl, imageUrl } = await getUploadUrl(
            file.name,
            file.type,
            surveyId
          );
          const config = {
            headers: {
              "Content-Type": file.type,
            },
          };
          await axios.put(uploadUrl, file, config);
          return imageUrl;
        })
      );
      options.callback("success", urls[0]);
    });

    // runs whenever a change has been made
    creator.onModified.add((sender, options) => {
      setIsModified(true);
    });

    async function setsurveyData() {
      try {
        setLoading(true);
        const surveyData = await getSurvey(surveyId);
        creator.JSON = surveyData.survey;
        if (surveyData.editable === false) {
          creator.readOnly = true;
          setShowToast(true);
        } else creator.readOnly = false;
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }

    setsurveyData();
  }, [surveyId]); // eslint-disable-line

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowToast(false);
  };

  if (loading)
    return (
      <LoadingPage type="loading" header="Loading">
        Launching
      </LoadingPage>
    );

  return (
    <React.Fragment>
      <React.StrictMode>
        <Snackbar
          open={showToast}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="warning"
            sx={{ width: "100%" }}
          >
            This Survey may not be edited, as there is at least one completed
            attempt
          </Alert>
        </Snackbar>
        <SurveyCreator.SurveyCreatorComponent creator={creator} />
      </React.StrictMode>
    </React.Fragment>
  );
}
