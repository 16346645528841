import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getSurvey,
  getSurveyAttempts,
  createSurveyAttempt,
} from "../../api/survey";
import Storage from "../../utils/Storage";
import AttemptsPage from "./components/AttemptsPage";
import SurveyPage from "./components/SurveyPage";
import LoadingPage from "../../components/LoadingPage";

const SurveyComponent = () => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [showAttemptsPage, setShowAttemptsPage] = useState(false);
  const [attempts, setAttempts] = useState([]);
  const [cachedData, setCachedData] = useState({});
  const [survey, setSurvey] = useState({});
  const [attemptNo, setAttemptNo] = useState(null);
  const [viewOnly, setViewOnly] = useState(false);
  const [limitReached, setLimitReached] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false);
  const [backText, setBackText] = useState("Return to list");
  const user = Storage.getUser();
  const { surveyId } = useParams();

  const getLastAttempt = (attempts) => {
    const cache = Storage.getSurveyCache();

    if (
      cache &&
      cache.userId === user?.userId &&
      cache.id !== surveyId &&
      cache.registrationId === user?.registrationId
    ) {
      console.log("Using Local Cached Survey");
      return cache;
    }

    let filteredAttempts = attempts
      .filter((a) => !a.isCompleted)
      .map((att) => ({
        data: att.answers,
        currPage: att.lastPageNo,
        registrationId: att.surveyIdUserIdRegistrationId.split("#")[2],
        attemptNo: att.surveyIdUserIdRegistrationId?.split("#")[3],
        completed: att.isComplete ? true : false,
        grade: null,
        lastUpdated: att.updatedAt,
      }));
    if (filteredAttempts.length === 0) return null;

    console.log("Using Remote Attempt Data");
    return filteredAttempts.reduce((a, b) => {
      return new Date(a.lastUpdated) > new Date(b.lastUpdated) ? a : b;
    });
  };

  useEffect(() => {
    const num = attempts.length;
    const limit = survey?.submissionLimit || 0;
    if (!(limit === 0 || num < limit)) setLimitReached(true);
  }, [attempts, survey]);

  const startNewAttempt = async () => {
    try {
      let attemptsData = await getSurveyAttempts(surveyId, user.instanceId);
      setAttempts(attemptsData);
      const num = attemptsData.length;
      if (!limitReached) {
        setIsLoading(true);
        setAttemptNo(num);
        setCachedData({});
        setViewOnly(false);
        let start = Date.now();
        await createSurveyAttempt(
          surveyId,
          user.instanceId,
          {},
          { attemptNo: num, startTime: start }
        );
        setIsCompleted(false);
        setShowAttemptsPage(false);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      handleError(err.message || "Unknown Error Occurred");
    }
  };

  const viewAttempt = (att) => {
    setIsCompleted(true);
    setCachedData({
      data: att.answers,
      currPage: att.lastPageNo,
      registrationId: att.surveyIdUserIdRegistrationId.split("#")[2],
      completed: att.isComplete,
      grade: null,
    });
    setViewOnly(true);
    setShowBackButton(true);
    setShowAttemptsPage(false);
  };

  const handleError = (message) => {
    setError(message);
    setIsLoading(false);
  };

  useEffect(() => {
    const setsurveyModel = async () => {
      try {
        setIsLoading(true);
        if (!user.registrationId)
          return handleError("No registrationId found!");
        const surveyData = await getSurvey(surveyId);
        if (!surveyData?.survey?.pages?.[0])
          return handleError("No survey data found.");
        setSurvey(surveyData.survey);

        let attemptsData = await getSurveyAttempts(surveyId, user.instanceId);
        setAttempts(attemptsData);
        const lastAttempt = getLastAttempt(attemptsData);
        if (!Array.isArray(attemptsData))
          return handleError("Error retrieving attempts.");
        if (surveyData?.survey?.multipleSubmissions) {
          if (surveyData?.survey.submissionPastResults) {
            setShowBackButton(true);
          }
          if (lastAttempt && !lastAttempt.completed) {
            setCachedData(lastAttempt);
            setAttemptNo(lastAttempt.attemptNo);
          } else if (attemptsData.length < 1) {
            await createSurveyAttempt(
              surveyId,
              user.instanceId,
              {},
              { startTime: Date.now() }
            );
          } else {
            if (!surveyData?.survey?.submissionPastResults) {
              await createSurveyAttempt(
                surveyId,
                user.instanceId,
                {},
                { attemptNo: attemptsData.length, startTime: Date.now() }
              );
            } else {
              setShowAttemptsPage(true);
              setAttempts(attemptsData);
            }
          }
        } else {
          //check for cache
          if (attemptsData.length < 1) {
            await createSurveyAttempt(
              surveyId,
              user.instanceId,
              {},
              { startTime: Date.now() }
            );
          } else {
            if (attemptsData.length === 1) {
              const att = attemptsData[0];
              if (att.isCompleted) {
                setIsCompleted(true);
              }
              setCachedData({
                data: att.answers,
                currPage: att.lastPageNo,
                registrationId: att.surveyIdUserIdRegistrationId.split("#")[2],
                attemptNo: null,
                completed: att.isComplete,
                grade: null,
              });
            } else {
              return handleError("Multiple attempts not allowed.");
            }
          }
        }
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        handleError(err.message || "Unknown Error Occurred");
      }
    };

    setsurveyModel();
  }, [surveyId, user.instanceId, user.registrationId]); // eslint-disable-line

  const handleReset = async () => {
    try {
      let attemptsData = await getSurveyAttempts(surveyId, user.instanceId);
      setAttempts(attemptsData);
      setShowAttemptsPage(true);
      setAttemptNo(null);
      setViewOnly(false);
      setCachedData({});
    } catch (e) {
      console.log(e);
      handleError(e.message || "Unknown Error Occurred");
    }
  };

  const handleResume = (att) => {
    const tmp = att.surveyIdUserIdRegistrationId.split("#");
    const regId = tmp[2];
    const attNo = tmp[3] ? parseInt(tmp[3]) : null;
    const cache = Storage.getSurveyCache();
    setCachedData({});
    setIsCompleted(false);
    setAttemptNo(null);
    setViewOnly(false);

    if (
      cache.registrationId === regId &&
      (attNo === null || cache.attemptNo === attNo)
    ) {
      console.log("Resuming with cached data...");
      setCachedData(cache);
    } else {
      console.log("Resuming with remote data...");
      setCachedData({
        data: att.answers,
        currPage: att.lastPageNo,
        registrationId: regId,
        attemptNo: attNo,
        completed: att.isComplete ? true : false,
        grade: null,
        lastUpdated: att.updatedAt,
      });
    }
    setAttemptNo(attNo);
    setShowBackButton(true);
    setBackText("Return to list");
    setShowAttemptsPage(false);
  };

  if (error) return <LoadingPage type="error">{error}</LoadingPage>;

  if (isLoading)
    return (
      <LoadingPage type="loading" header="Loading">
        Launching...
      </LoadingPage>
    );

  if (showAttemptsPage) {
    return (
      <AttemptsPage
        limitReached={limitReached}
        viewAttempt={viewAttempt}
        startNewAttempt={startNewAttempt}
        survey={survey}
        attempts={attempts}
        setShowAttmeptsPage={setShowAttemptsPage}
        handleResume={handleResume}
      />
    );
  }

  if (survey) {
    return (
      <SurveyPage
        userId={user.userId}
        viewOnly={viewOnly}
        id={surveyId}
        surveyJSON={survey}
        registrationId={user.registrationId}
        attemptNo={attemptNo}
        instanceId={user.instanceId}
        cachedData={cachedData}
        isCompleted={isCompleted}
        setIsCompleted={setIsCompleted}
        handleReset={handleReset}
        startNewAttempt={startNewAttempt}
        showBackButton={showBackButton}
        backText={backText}
      />
    );
  }

  return (
    <LoadingPage type="loading" header="Loading">
      Launching
    </LoadingPage>
  );
};

export default SurveyComponent;
