import React from "react";
import ReactDOM from "react-dom";
import "@fontsource/open-sans";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import NavigateSetter from "./NavigateSetter";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <NavigateSetter />
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
