import { useEffect, useState } from "react";
import * as Survey from "survey-core";
import * as ReactSurvey from "survey-react-ui";
import * as css from "survey-core/defaultV2.css";

import "./PreviewTab.css";

Survey.StylesManager.applyTheme("defaultV2");

const PreviewTab = ({ survey }) => {
  const [model, setModel] = useState(undefined);
  const [error, setError] = useState("");

  useEffect(() => {
    const setSurveyModel = async () => {
      try {
        if (survey?.survey?.pages?.[0]) {
          let newModel = new ReactSurvey.Model(survey.survey);
          setModel(newModel);
        } else {
          setError("No Survey data found.");
        }
      } catch (err) {
        setError(err.message || "Unknown Error Occurred");
      }
    };

    setSurveyModel();
  }, [survey]);

  const onComplete = async (survey, options) => {
    // review results
    survey.clear(false);
    survey.mode = "display";
    survey.questionsOnPageMode = "singlePage";
  };

  if (error) return <div>{error}</div>;

  if (model) {
    return (
      <>
        <ReactSurvey.Survey model={model} onComplete={onComplete} css={css} />
      </>
    );
  }
  return <div>loading</div>;
};

export default PreviewTab;
