import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button, Chip } from "@mui/material";
import "./ResultsTab.css";
import MUIDataTable from "mui-datatables";
import { getReportExport } from "../../../../api/survey";
import useOrientation from "../../../../utils/useOrientation";
import { useMemo, useState } from "react";
import ViewAttemptComponent from "./components/ViewAttempt";

const checkStatus = (attempt) => {
  if (attempt.isCompleted) {
    return "COMPLETE";
  } else if (attempt.startTime) {
    return "IN PROGRESS";
  } else {
    return "NOT STARTED";
  }
};

const calcTimeSpent = (attempt) => {
  if (attempt.isCompleted) {
    let diff =
      (new Date(attempt.endTime) - new Date(attempt.startTime)) / 1000;
    let h = Math.floor(diff / 3600);
    let m = Math.floor((diff % 3600) / 60);
    let s = diff % 60;
    if (isNaN(h) || isNaN(m) || isNaN(s)) return "";
    return `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}:${s.toFixed(0) < 10 ? "0" + s.toFixed(0) : s.toFixed(0)
      }`;
  } else return "";
};

const downloadFile = (url) => {
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url)
}

const ResultsTab = ({ surveyId, attempts, users }) => {
  // const [data, setData] = useState([]);
  const [viewAttempt, setViewAttempt] = useState(false);
  const [attemptInfo, setAttemptInfo] = useState({});
  const orientation = useOrientation();
  const data = useMemo(() => {
    if (Array.isArray(attempts) && Array.isArray(users)) {
      let newData = attempts.map((attempt) => {
        let attempt_uId = attempt.surveyIdUserIdRegistrationId.split("#")[1];
        let user = users.find((i) => i.userId === attempt_uId);
        if (!user) return null
        return {
          name: user?.firstName + " " + user?.lastName,
          email: user?.email,
          timeSpent: calcTimeSpent(attempt),
          status: checkStatus(attempt),
          grade: attempt.grade,
          isCompleted: attempt.isCompleted,
          ...attempt,
        };
      });

      return newData.filter(a => a !== null);
    }
    return []
  }, [attempts, users])

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              padding: 0,
            },
          },
        },
        MuiTableBody: {
          styleOverrides: {
            root: {
              border: "1px black",
              borderStyle: "solid none",
            },
          },
        },
        MUIDataTable: {
          styleOverrides: {
            root: {
              marginTop: 20,
              boxShadow: "none",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            contentWrapper: {
              justifyContent: "center",
            },
            toolButton: {
              marginLeft: 0,
              marginRight: 0,
              paddingLeft: 0,
              paddingRight: 0,
            },
            data: {
              fontWeight: "bolder",
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              padding: 5,
              borderStyle: "none",
              textAlign: "center",
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            root: {
              height: 22,
            },
            label: {
              paddingRight: 0,
              paddingLeft: 0,
            },
          },
        },
        MUIDataTablePagination: {
          styleOverrides: {
            navContainer: {
              justifyContent: "center",
            },
          },
        },
      },
    });

  const options = {
    rowsPerPageOptions: [10],
    download: false,
    print: false,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search By Name",
    sortFilterList: false,
    filter: false,
    viewColumns: false,
    responsive: "standard",
    selectableRows: "none",
  };

  const columns = [
    {
      name: "surveyIdUserIdRegistrationId",
      label: "",
      options: {
        searchable: false,
        display: "excluded",
        filter: false,
        sort: false,
        viewColumns: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        sort: true,
        filter: true,
        sortThirdClickReset: true,
        setCellHeaderProps: (val) => {
          return {
            className: "leftAlign",
            style: {
              paddingLeft: 20,
            },
          };
        },
        setCellProps: (val) => {
          return {
            className: "leftAlign leftPadding",
          };
        },
        customBodyRender: (val, tableMeta) => {
          if (orientation === "portrait") {
            let bgColor;
            if (tableMeta.rowData[3] === "COMPLETE") {
              bgColor = "#00ab3c";
            } else if (tableMeta.rowData[3] === "IN PROGRESS") {
              bgColor = "#ffbd02";
            } else {
              bgColor = "#cccccc";
            }
            return (
              <>
                {val}
                <span
                  style={{ backgroundColor: bgColor }}
                  className="dot"
                ></span>
              </>
            );
          } else return val;
        },
      },
    },
    {
      name: "email",
      label: "Email Address",
      options: {
        searchable: false,
        sort: true,
        filter: true,
        sortThirdClickReset: true,
        setCellHeaderProps: (val) => {
          return {
            className: "leftAlign",
          };
        },
        setCellProps: (val) => {
          return {
            className: "leftAlign",
          };
        },
        display: orientation === "landscape" ? true : false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (val, tableMeta, updateValIndex) => {
          let bgColor;
          if (val === "COMPLETE") {
            bgColor = "#00ab3c";
          } else if (val === "IN PROGRESS") {
            bgColor = "#ffbd02";
          } else {
            bgColor = "#cccccc";
          }
          return (
            <Chip
              sx={{
                width: "50%",
                backgroundColor: bgColor,
                color: "white",
                fontWeight: "bolder",
                fontSize: ".7rem",
              }}
              className="status-chip"
              label={val}
            />
          );
        },
        searchable: false,
        sort: true,
        filter: true,
        sortThirdClickReset: true,
        display: orientation === "landscape" ? true : false,
      },
    },
    {
      name: "timeSpent",
      label: "Time Spent",
      options: {
        searchable: false,
        sort: true,
        filter: true,
        sortThirdClickReset: true,
        display: orientation === "landscape" ? true : false,
      },
    },
    {
      name: "isCompleted",
      label: " ",
      options: {
        customBodyRender: (val, tableMeta, updateVal) => {
          if (val) {
            return (
              <Button
                sx={{ textTransform: "none", padding: 0, color: "#245585" }}
                onClick={() => showAttemptData(tableMeta.rowData[0])}
              >
                Review
              </Button>
            );
          }
        },
        searchable: false,
        filter: false,
        sort: false,
        viewColumns: false,
      },
    },
  ];

  const goBack = () => {
    setViewAttempt(false);
  };
  const showAttemptData = (id) => {
    setAttemptInfo(data.find((i) => i.surveyIdUserIdRegistrationId === id));
    setViewAttempt(true);
  };

  const exportReport = async () => {
    const res = await getReportExport(surveyId);
    console.log(res)
    downloadFile(res.url)
  }

  return (
    <>
      {viewAttempt === false ? (
        <ThemeProvider theme={getMuiTheme()}>
          <div style={{ width: '100%', textAlign: 'right' }}>
            <Button onClick={exportReport} sx={{ margin: '1rem', padding: '.5rem' }} variant="contained">Export</Button>
          </div>
          <MUIDataTable
            title="Attempts"
            data={data}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      ) : (
        <ViewAttemptComponent
          attemptInfo={attemptInfo}
          goBack={() => goBack()}
        />
      )}
    </>
  );
};

export default ResultsTab;
