import { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Question from "./components/Question";
import ResponseTable from "./components/ResponseTable";

const QuestionTab = ({ survey, attempts }) => {
  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalResponses, setModalResponses] = useState(0);
  const [modalUnanswered, setModalUnanswered] = useState(0);
  const [modalData, setModalData] = useState({});
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    survey?.survey?.pages.forEach((page) => {
      if (page.elements && page.name !== "Start_Page") {
        setQuestions((questions) => questions.concat(page.elements));
      }
    })
  }, [survey]);

  const handleOpen = (data, responses, unanswered) => {
    if (data.title) setModalTitle(data.title);
    else setModalTitle(data.name);
    setModalResponses(responses);
    setModalUnanswered(unanswered);
    setModalData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!attempts?.length > 0) return <>No Data found</>

  return (
    <>
      <div style={{ margin: "10px" }}>
        {questions.map((q, i) => {
          return (
            <Question
              key={i}
              data={q}
              attempts={attempts}
              setShowModal={handleOpen}
            />
          );
        })}
      </div>

      <Dialog maxWidth="md" fullWidth={true} open={open} onClose={handleClose}>
        <DialogTitle
          style={{ fontWeight: "bold", borderBottom: "1px solid #eeeeee" }}
        >
          Question Details
        </DialogTitle>
        <DialogContent>
          <h1 style={{ fontSize: 18 }}>{modalTitle}</h1>
          <div>
            <div>{modalResponses - modalUnanswered} Responses</div>
            <ResponseTable data={attempts} modalData={modalData} />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default QuestionTab;
