import { useNavigate, useSearchParams } from "react-router-dom";
import { login } from "../../api/auth";
import { useEffect, useState } from "react";
import { roles } from "../../utils/constants";
import Storage from "../../utils/Storage";
import LoadingPage from "../../components/LoadingPage";

const routes = {
  builder: {
    route: "builder",
    routeName: "Survey Builder",
    role: [roles.CREATOR, roles.ADMIN],
    hasId: true,
  },
  survey: {
    route: "survey",
    routeName: "Survey",
    role: [roles.USER],
    hasId: true,
  },
  results: {
    route: "results",
    routeName: "Results",
    role: [roles.INSTRUCTOR, roles.ADMIN, roles.CREATOR],
    hasId: true,
  },
};

function Sso({ setTitle, setUrl, setTheme }) {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const accountId = searchParams.get("accountId");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuth = async () => {
      try {
        Storage.removeUser();
        const {
          claims,
          user,
          token: accessToken,
          refreshToken,
          accountSettings,
        } = await login(accountId, token);
        Storage.setUser({
          accessToken,
          refreshToken,
          ...user,
          ...claims,
        });

        // set Theme
        setTheme(accountSettings);

        if (Object.keys(routes).includes(claims.route)) {
          if (routes[claims.route].role.includes(claims.role)) {
            let route = `/${claims.route}`;
            if (routes[claims.route].hasId) route += `/${claims.surveyId}`;
            setTitle(routes[claims.route].routeName);
            if (claims.cmsUrl) setUrl(claims.cmsUrl);
            navigate(route, { replace: true });
          } else {
            setError("You don't have permission to go here");
            navigate("/invalid");
          }
        } else {
          setError("Missing Route");
        }
      } catch (err) {
        console.log(err);
        setError(err.message);
      }
    };
    if (token && accountId) handleAuth();
  }, []); // eslint-disable-line

  if (!token) return <h3>Missing Token.</h3>;
  if (!accountId) return <h3>Missing AccountId</h3>;
  if (error)
    return (
      <>
        <h3>Error</h3>
        <p>{error}</p>
      </>
    );

  return (
    <LoadingPage type="loading" header="Loading">
      Authenticating...
    </LoadingPage>
  );
}

export default Sso;
