import { CombobulateLogo } from '../svg/index';
import "./LoadingPage.css"
import { Typography, LinearProgress, CircularProgress } from '@mui/material'

export default function LoadingPage({ children, type, header, progress, showLogo }) {
  type = type ?? 'message';
  if (header === undefined && type === 'error') {
    header = 'Error';
  }

  const showProgress = type !== 'message' && type !== 'error';

  return (
    <div className={"loading-wrapper"}>
      <div className={"loading-root"}>
        <div className="header" sx={{ justifyContent: showLogo ? "space-between" : "center" }}>
          {showLogo && <CombobulateLogo />}

          <Typography variant="h6" className={"headerText"}>
            {header}
          </Typography>
        </div>

        <LinearProgress
          variant="determinate"
          value={typeof progress === 'number' ? progress : 100}
        />

        <Typography className={"messageText"} variant="body1">
          {children}
        </Typography>

        {showProgress && (
          <CircularProgress
            style={{ opacity: typeof progress === 'number' ? 0 : 1 }}
            size={60}
            thickness={6}
            disableShrink={type === 'waiting'}
          />
        )}
      </div>
    </div>
  );
}
