import React, { useEffect, useState } from "react";
import * as SurveyCreator from "survey-creator-react";
import { updateSurvey } from "../../../api/survey";
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/material";
import "./CustomPropertyGridWrapper.css";

export default function CustomPropertyGridWrapper(props) {
  const [isSaving, setIsSaving] = useState(false);
  const [lastSavedAt, setLastSavedAt] = useState("");

  const model = props.model;
  const saveable = !model.creator.readOnly;

  useEffect(() => {
    if (saveable === false) {
      setLastSavedAt('Survey has been attempted. Unable to update!');
      return;
    }
  }, [saveable])

  if (!model) return null;

  const btnSaveSurveyClick = async () => {
    try {
      setIsSaving(true);
      const survey = await updateSurvey(
        props.surveyId,
        props.model.creator.JSON
      );
      props.setModify(false);
      const stringDate = new Date(survey.survey.updatedAt).toLocaleTimeString();
      setLastSavedAt(`Last saved at ${stringDate}`);
    } catch (e) {
      console.log(e);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <React.Fragment>
      <SurveyCreator.PropertyGridComponent
        model={model}
      ></SurveyCreator.PropertyGridComponent>

      <div className="save-wrapper">
        <div className="grid-wrapper-root">
          <Button
            variant="contained"
            color="primary"
            disabled={!props.isModified || isSaving || !saveable}
            onClick={btnSaveSurveyClick}
            className="save-button"
            sx={{
              width: "88%",
              minHeight: "50px",
              color: "white",
              backgroundColor: "#113483",
              fontSize: "16px",
              fontWeight: "600",
              textTransform: "none",
            }}
          >
            {saveable ? "Save Changes" : "Read Only"}
          </Button>
        </div>
        <div className="save-text-box" >
          <span>
            {isSaving && <CircularProgress size={15} />}
          </span>
          <span style={{ marginLeft: '.5rem' }}>
            {lastSavedAt && lastSavedAt}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
}
