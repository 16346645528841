import axios from "axios";
import Storage from "../utils/Storage";
import History from "../history";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = Storage.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/api/v1/auth/signin" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await instance.post("/api/v1/auth/refresh", {
            refreshToken: Storage.getLocalRefreshToken(),
          });

          const { token } = rs.data;
          Storage.updateLocalAccessToken(token);

          return instance(originalConfig);
        } catch (_error) {
          Storage.removeUser();
          History.push('/invalid');
          return Promise.reject(_error);
        }
      }
    }else if (err.response.status === 401 || err.response.status === 403){
      History.push('/invalid');
      return Promise.reject(err);
    }else if (err.response.status === 404){
      History.push('*');
      return Promise.reject(err);
    }

    return Promise.reject(err);
  }
);

export default instance;
