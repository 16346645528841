
const InvalidComponent = () => {

    return (
        <div style={{ margin: '10px' }}>
            <h1>Not Authorized!</h1>
            <p>You are not authorized to view this page. Please login to the Combobulate CMS to continue.</p>

        </div>
    )

}

export default InvalidComponent;