import "./App.css";
import defaultCss from "./utils/cssVars";
import Test from "./routes/Test";
import SSO from "./routes/Sso";
import Survey from "./routes/Survey";
import Builder from "./routes/Builder";
import Header from "./components/Header";
import InvalidComponent from "./routes/Invalid";
import NotFoundComponent from "./routes/NotFound";
import { Routes, Route, useLocation } from "react-router-dom";
import { useState } from "react";
import ResultsComponent from "./routes/Results/Results";

function App() {
  const [title, setTitle] = useState("Survey");
  const [combobulateUrl, setCombobulateUrl] = useState("/");
  let location = useLocation();
  const showHeader =
    location &&
    location.pathname &&
    ["", "builder", "invalid"].includes(location.pathname.split("/")[1]);

  const setTheme = (settings) => {
    if (settings?.themeColor) {
      localStorage.setItem("themeColor", settings.themeColor);
    } else {
      localStorage.removeItem("themeColor");
    }
  };

  // get theme from localstorage
  if (localStorage.getItem("themeColor")) {
    let theme = localStorage.getItem("themeColor");
    document.documentElement.style.setProperty("--primary", theme);
    document.documentElement.style.setProperty("--green", theme);
  } else {
    document.documentElement.style.setProperty(
      "--primary",
      defaultCss["--primary"]
    );
    document.documentElement.style.setProperty(
      "--green",
      defaultCss["--green"]
    );
  }

  return (
    <div className="App">
      {showHeader && <Header title={title} link={combobulateUrl} />}
      <Routes>
        <Route path="/survey/:surveyId" element={<Survey />} />
        <Route path="/builder/:surveyId" element={<Builder />} />
        <Route path="/results/:surveyId" element={<ResultsComponent />} />
        <Route
          path="/sso"
          element={
            <SSO
              setTitle={setTitle}
              setUrl={setCombobulateUrl}
              setTheme={setTheme}
            />
          }
        />
        {process.env.REACT_APP_STAGE !== "prod" && (
          <Route path="/" element={<Test />} />
        )}
        <Route path="/invalid" element={<InvalidComponent />} />
        <Route path="*" element={<NotFoundComponent />} />
      </Routes>
    </div>
  );
}

export default App;
