import "./Question.css";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Button, Paper } from "@mui/material";
import { useEffect, useState } from "react";

const Question = ({ data, attempts, setShowModal }) => {
  const [answers, setAnswers] = useState([]);
  const [unanswered, setUnanswered] = useState(0);
  const [responses, setResponses] = useState(0); // the length of responses (answers) for the question
  const questionType = data.type;

  const setChoices = () => {
    // returns all the categories that should be in the Bar chart
    if (questionType === "radiogroup" || questionType === "dropdown") {
      return data.choices.sort().map((i) => {
        if (i.value) {
          return i.value;
        } else return i;
      });
    } else if (questionType === "imagepicker") {
      return data.choices.map((i) => i.value).sort();
    } else if (questionType === "boolean") {
      return ["false", "true"];
    } else {
      return [];
    }
  };
  const choices = setChoices();

  const initMap = () => {
    // add all the choices into the map and sets the value to 0
    let map = new Map();
    choices.forEach((i) => {
      let k = i;
      if (questionType === "checkbox") {
        k = `${i}`;
      } else if (questionType === "boolean") {
        k = `${i}`;
      }
      map.set(k, 0);
    });
    return map;
  };

  const [map, setMap] = useState(initMap());

  useEffect(() => {
    let ans = attempts.filter((i) => i.isCompleted); // remove incompleted attempts
    setResponses(ans.length);
    let unAns = 0;
    ans = ans
      .filter((i) => {
        // remove unanswered attempts
        if (i.answers && i.answers[data.name] !== undefined) {
          return true;
        } else {
          unAns++;
          return false;
        }
      })
      .map((i) => {
        return i.answers[data.name];
      });
    setUnanswered(unAns);
    setAnswers(ans); // all responses for the question
    let ansMap = map;
    ans.forEach((e) => {
      // for each response, check if in the map and increment, else add to map
      let k = `${e}`;
      if (e && questionType === "checkbox") {
        k = `${e.sort()}`;
      } else if (e && questionType === "ranking") {
        k = `${e}`;
      } else if (e && questionType === "boolean") {
        k = `${e}`;
      }
      //   } else if (questionType === "text") {
      //     if (e === `${CORRECT_ANSWER}`) k = "Correct";
      //     else k = "Incorrect";
      //   }
      if (ansMap.has(k)) {
        let n = ansMap.get(k);
        ansMap.set(k, n + 1);
      } else {
        ansMap.set(k, 1);
      }
    });
    setMap(ansMap);
  }, [data, attempts, questionType, map]);

  const getCategories = () => {
    // sets the categories that will be shown in the Bar chart
    if (questionType === "radiogroup" || questionType === "dropdown") {
      return data.choices.sort().map((i) => (i.text ? i.text : i));
    } else if (questionType === "imagepicker") {
      return data.choices.map((i) => i.value).sort();
    } else if (questionType === "boolean") {
      return ["false", "true"];
    } else if (questionType === "text") {
      return Array.from(new Set(answers)).sort();
    } else if (questionType === "checkbox") {
      let optionList = Array.from(
        new Set(
          answers.map((i) => {
            let opt = i.sort().map((item) => {
              let choice = data.choices.find((c) => c.value === item);
              return choice ? choice.text : item;
            });
            return `${opt}`;
          })
        )
      );
      return optionList;
    } else if (questionType === "ranking") {
      return Array.from(
        new Set(
          answers.map((i) => {
            let opt = i.map((item) => {
              let choice = data.choices.find((c) => c.value === item);
              return choice ? choice.text : item;
            });
            return `${opt}`;
          })
        )
      );
    } else {
      return Array.from(map.keys()).sort();
    }
  };

  const getData = () => {
    // sets the data that will be shown in the Bar chart
    let d = Array.from(map.keys())
      .sort()
      .map((i) => {
        if (map.get(i)) {
          return { y: map.get(i) };
        } else return { y: 0 };
      });
    return d;
  };

  const options = () => {
    // options for HighCharts
    return {
      accessibility: { enabled: false },
      chart: {
        type: "bar",
        height: 250,
        width: 700,
      },
      plotOptions: {
        series: {
          groupPadding: 0,
          pointWidth: 30,
          pointPadding: 0.1,
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return `${this.y} (${((this.y / responses) * 100).toFixed(1)} %)`;
            },
            crop: false,
            overflow: "allow",
          },
        },
      },
      xAxis: {
        categories: getCategories(),
      },
      yAxis: {
        visible: true,
        gridLineWidth: 0,
        title: {
          text: "Responses",
        },
        max: responses + 1,
      },
      title: {
        text: "",
      },
      series: [
        {
          name: "",
          data: getData(),
        },
      ],
      tooltip: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
    };
  };

  return (
    <>
      <Paper className="paper">
        <div style={{ margin: "10px" }}>
          <h1 style={{ fontSize: 18 }}>
            {data.title ? data.title : data.name}
          </h1>
          <div>
            <div className="corrResp">
              {responses - unanswered} Responses / {responses} Submissions
            </div>
            {questionType === "text" ||
              questionType === "comment" ||
              questionType === "email" ? (
              <div>
                <br />
                <Button
                  onClick={() => {
                    setShowModal(data, responses, unanswered);
                  }}
                  color="primary"
                  className="view-details-button"
                >
                  View Responses
                </Button>
              </div>
            ) : questionType === "html" || questionType === "file" ? (
              <div>
                <br />
                N/A
              </div>
            ) : (
              <HighchartsReact highcharts={Highcharts} options={options()} />
            )}
          </div>
        </div>
      </Paper>
    </>
  );
};

export default Question;
