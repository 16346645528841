import ls from 'localstorage-slim';
ls.config.encrypt = false;

class Storage {
  getLocalRefreshToken() {
    const user = ls.get("user");
    return user?.refreshToken;
  }

  getLocalAccessToken() {
    const user = ls.get("user");
    return user?.accessToken;
  }

  updateLocalAccessToken(token) {
    let user = ls.get("user");
    user.accessToken = token;
    ls.set('user', user)
  }

  getSurveyCache() {
    return ls.get("survey_cache");
  }

  setSurveyCache(cacheData) {
    ls.set("survey_cache", cacheData)
  }

  clearSurveyCache() {
    ls.remove("survey_cache")
  }

  getUser() {
    return ls.get("user");
  }

  setUser(user) {
    ls.set("user", user);
  }

  removeUser() {
    ls.remove("user");
  }

  clear() {
    ls.clear()
  }
  
}

export default new Storage();
